@font-face {
  font-family: MontserratLight;
  src: url("../../assets/font/Montserrat-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("../../assets/font/Montserrat-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MontserratBold;
  src: url("../../assets/font/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
