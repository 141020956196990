/* stylelint-disable property-no-vendor-prefix */
@import "variables";
@import "mixins";

// TODO uncomment if needed for radio later, otherwise rm
// input[type="radio"] + label {
//   // position: absolute;
//   // top: 0;
//   // left: 0;
//   font-weight: 400;
//   padding: 0 2rem;
//   color: $dark;
//   transition: all .3s;
//   margin-bottom: 0;

//   // &--radio {
//   margin-right: 3rem;
//   font-size: inherit;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   flex: 0 0 100%;
// }

// .radio-button {
//   height: 1.5rem;
//   width: 1.5rem;
//   border: 2px solid;
//   border-radius: 50%;
//   position: relative;
//   margin-right: 1rem;
//   display: flex;
//   flex: 0 0 1.5rem;
//   align-items: center;
//   justify-content: center;

//   &::after {
//     content: "";
//     display: none;
//     width: 1rem;
//     height: 1rem;
//     border-radius: 50%;
//     background-color: $secondary;
//   }
// }

// input[type="radio"]:checked + label .radio-button {
//   border-color: $primary;
// }

// input[type="radio"]:checked + label .radio-button::after {
//   display: block;
// }

input[type="checkbox"] {
  position: absolute;
  left: -999em;
}

input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -10%;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: 1px solid $secondary;
  margin-right: .2rem;
}

input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  width: .85rem;
  height: .55rem;
  top: .5rem;
  left: .35rem;
  border: 3px solid $primary;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg) scale(1);
  -webkit-transform: rotate(-45deg) scale(1);
  -moz-transform: rotate(-45deg) scale(1);
  -o-transform: rotate(-45deg) scale(1);
  -ms-transform: rotate(-45deg) scale(1);
  transition: transform .2s ease-out, -webkit-transform .2s ease-out;

  @include respond(sm,md) {
    top: .4rem;
  }
  @include respond(xs) {
    top: .3rem;
  }
}

input[type="checkbox"] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
