/* stylelint-disable property-no-vendor-prefix */
@import "variables";
@import "mixins";

html {
  font-size: 16px;

  @include respond(xs) {
    font-size: 11px;
  }

  @include respond(sm, md) {
    font-size: 12px;
  }
}

table {
  thead {
    tr {
      td {
        padding: 5px 10px;
        color: $light;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 15px;
        border-bottom: 2px solid $secondary;
      }
    }
  }
}

.content-text {
  font-family: $font-family-sans-serif;
}

.tooltip .tooltip-inner {
  color: $light;
  background-color: $secondary;
  font-family: $font-family-sans-serif;
}

.tooltip .arrow::before {
  border-top-color: $secondary;
}

.tooltip.show {
  opacity: 1;
}

h1,
h2 {
  font-family: $font-family-montserrat-bold;
}

h6 {
  font-family: $font-family-montserrat;
  font-size: 1.2rem;
  color: $blue-gray;
  letter-spacing: 2.16px;

  @include respond(xs,sm) {
    font-size: 1.5rem;
    text-align: center;
  }
}

input::placeholder {
  font-size: .9rem;
  color: $gray-500;
}

strong {
  $font-family: $font-family-montserrat;
}

a {
  $font-family: $font-family-montserrat-bold;
}

.bold {
  font-family: $font-family-montserrat-bold;
}

.color-primary {
  color: $primary;
}

.color-red {
  color: $red;
}
