@import "variables";

.list-wrapper {
  button {
    min-height: 2rem;
  }

  .table {
    thead {
      height: 56px;
      background-color: $brown;
      color: $light;
      border-radius: 5px;
    }

    .item-row {
      .action-link.btn {
        height: 22px;
        width: 60px;
        font-size: 12.5px;
      }

      // TODO remove this after adding implementation for checking when there is overflowing text in the lib list cell
      // ngb-highlight:hover {
      //   display: none;
      // }
    }
  }

  .page-options-size {
    font-size: 15px;

    .dropdown {
      padding: 0 2px;
    }
  }

  .page-options-pagination {
    .page-link:hover,
    .page-link {
      color: $secondary;
      background-color: $light;
      box-shadow: unset !important;
    }

    .page-item.active,
    .page-item:active {
      .page-link {
        background-color: $primary !important;
        color: $white !important;
      }
    }

    .page-item.disabled {
      .page-link {
        background-color: $light !important;
        color: $black !important;
      }
    }
  }

  .mobile-data {
    hr: {
      color: $secondary !important;
    }
  }
}
