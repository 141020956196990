.modal-content {
  padding: 1.5rem;

  .modal-header {
    border-bottom: unset;
  }

  .modal-footer {
    border-top: unset;
    justify-content: start;

    button {
      width: unset !important;
    }
  }
}
