@import "variables";
@import "mixins";

.btn-primary,
.btn-outline-primary {
  min-height: 2.5rem;
  padding: 5px 10px;

  @include respond(sm,md) {
    min-height: 2rem;
  }

  @include respond(xs) {
    min-height: 2rem;
    width: 100%;
  }
}

.btn-search {
  padding: 5px 50px;
}

.btn-group {
  padding: 5px 30px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: $white !important;
}

.btn-outline-primary {
  color: $primary !important;

  &:hover,
  &:focus-visible {
    color: $white !important;
  }
}
